.hidden {
  display: none;
}

.form-title {
  font-weight: bold;
  text-decoration: underline;
}

tbody .ant-table-cell:empty:after,
td.ant-descriptions-item-content:empty:after,
.editable-cell-value-wrap:empty:after {
  content: '--';
}

.table-filter-wrapper {
  padding: 10px;
}

.table-filter-wrapper>* {
  width: 200px;
}

.ant-table-container .ant-table-body {
  max-height: calc(100vh - 231px);
  overflow-y: auto !important;
}

.ant-table-container .ant-table-content {
  max-height: calc(100vh - 240px);
  overflow-y: auto !important;
}

.ant-table-cell.cell-yan_yana {
  background-color: #e6fffb;
}

.ant-table-cell.cell-toplam_fiyat {
  background-color: #fff7e6;
}

.ant-table-cell.cell-consumed_m2 {
  background-color: #fff1f0;
}

.ant-table-cell.cell-remaining_m2 {
  background-color: #f6ffed;
}

.ant-table-cell.cell-bicak_fiyat {
  background-color: #fcffe6;
}

.ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 99;
}

.ant-descriptions .ant-form-item {
  margin-bottom: 0;
}

.btn-success {
  background: #28a745 !important;
}

.btn-success:hover {
  background: #218838 !important;
}

.w-100 {
  width: 100%;
}

.w-200p {
  width: 200px;
}

.mt-1r {
  margin-top: 1rem;
}

.mt-2r {
  margin-top: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.disabled-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2;
  cursor: not-allowed;
}

.disabled-overlay.flekso {
  width: calc(100% - 30px);
  margin: 0 15px;
  border-radius: 8px;
}

.ant-descriptions-small .ant-descriptions-row>.ant-descriptions-item-label,
.ant-descriptions-small .ant-descriptions-row>.ant-descriptions-item-content {
  padding: 8px !important;
}

@page {
  margin: 0;
}

.print-wrapper {
  padding: 20px;
  text-transform: uppercase;
}

.print-table {
  width: 100%;
  font-size: 14px;
}

.print-table tr td {
  padding: 5px;
  border: 1px solid #000;
  vertical-align: baseline;
}

.print-table .no-padding {
  padding: 0;
}

.print-table .no-border {
  border: 0;
}

.print-table .title {
  display: block;
  margin-bottom: 5px;
  font-style: italic;
  text-decoration: underline;
}

.print-table .title:after {
  content: ':';
}

/* .print-checkbox .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-width: 10px;
} */

.print-checkbox .ant-checkbox-wrapper {
  margin-right: 0;
}

.middle {
  vertical-align: middle !important;
}

.t-center {
  text-align: center;
}

.t-left {
  text-align: left;
}

.x-with-border .ant-radio-wrapper,
.x-with-border .ant-checkbox-wrapper {
  border: 3px solid transparent;
  border-radius: 6px;
}

.x-with-border .ant-radio-wrapper-checked,
.x-with-border .ant-checkbox-wrapper-checked {
  border-color: #003eb3 !important;
}

.x-with-border .ant-radio.ant-wave-target,
.x-with-border .ant-checkbox.ant-wave-target {
  display: none;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  padding: 12px;
}

.order-status-0 {
  background-color: #fff;
}

.order-status-1 {
  background-color: #ffd591;
}

.order-status-2 {
  background-color: #91caff;
}

.order-status-20 {
  background-color: #e3f5f2;
}

.order-status-21 {
  background-color: #85a5ff;
}

.order-status-22 {
  background-color: #ffa39e;
}

.order-status-23 {
  background-color: #cc9964;
}

.order-status-3 {
  background-color: #b7eb8f;
}

.link-like-button {
  padding: 0;
}

.link-like-button a {
  padding: 5px 8px;
}

.chart-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 300px;
  max-height: 620px;
  margin-bottom: 40px;
}

.ant-steps.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-content {
  width: 90px;
}

.ant-steps.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-inline-start: 36px;
}

.ant-steps.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-tail {
  margin-inline-start: 48px;
}
